import { FC, useMemo } from "react";

import { paths } from "config/paths";

import AssetsSingleMenu from "components/AssetsSinglePage/components/AssetsSingleMenu";
import { useLayoutAppNameContext } from "components/Layout/hooks";

import AmlSingleMenu from "../../../../AMLSinglePage/components/AmlSingleMenu";

import MobileHeaderSyncAccounts from "../MobileHeaderSyncAccounts";

const MobileHeaderRight: FC = () => {
  const { routePath } = useLayoutAppNameContext();

  const rightComponent = useMemo(() => {
    if (routePath === paths.SINGLE_ASSET) return <div className="header-mobile__asset-menu"><AssetsSingleMenu /></div>;
    if (routePath === paths.SINGLE_AML) return <div className="header-mobile__asset-menu"><AmlSingleMenu /></div>;

    const hideSync = [
      paths.DOCUMENTS,
      paths.SINGLE_ASSET,
      paths.SINGLE_AML,
      paths.SINGLE_AML_HISTORY,
      paths.TAX_REPORT_FORMED,
      paths.DOCUMENTS_TRANSACTION_EXPORT_FORMED,
      paths.DOCUMENTS_SOURCES_EXPORT_FORMED,
      paths.SETTINGS,
      paths.SETTINGS_PLAN_AND_PAYMENTS,
      paths.SETTINGS_SAFETY,
    ].includes(routePath as paths);
    if (hideSync) return null;
    return <MobileHeaderSyncAccounts/>;
  }, [routePath]);

  return (
    <div className="header-mobile__right">
      {rightComponent}
    </div>
  );
};

export default MobileHeaderRight;
