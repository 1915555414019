import { Drawer } from 'antd';
import { FC, ReactNode } from 'react';
import cn from "classnames";

import CloseIcon from "../Icons/CloseIcon";

export type TPreparedMobileDrawerProps = {
  visible?: boolean
  title?: string
  close: () => void
  header?: ReactNode
  hideHeader?: boolean
  className?: string
  headerClass?: string
  closeIcon?: JSX.Element
}
const PreparedMobileDrawer: FC<TPreparedMobileDrawerProps> = ({
  children,
  header,
  visible,
  title,
  close  ,
  closeIcon,
  className,
  headerClass,
  hideHeader,
}) => (
  <Drawer
    width="100%"
    placement="right"
    closable={false}
    open={visible}
    className={cn("drawer", className)}
  >
    <div className="drawer-content">
      {!hideHeader && <div className={cn("drawer-header", headerClass)}>
        <div className="drawer-close-icon" onClick={close}>
          {closeIcon ? closeIcon : <CloseIcon/>}
        </div>
        {title && <h2 className="drawer-title">{title}</h2>}
        {header}
      </div>}
      {children}
    </div>
  </Drawer>
);

export default PreparedMobileDrawer;
