import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useChainedLoading from "hooks/useChainedLoading";

import { amlPublicSingleStateSelector } from "store/aml/selectors";
import { publicAccountInfoStateSelector, publicAccountTransactionsStateSelector } from "store/public/selectors";

import { checkIfValidUUID } from "utils/checkValidUUID";

import { getUserInfoSelector } from "../../../../store/user/selectors";

export type TPublicChildLoaderContext = {
  loading?: boolean
  show404?: boolean
}
export const PublicChildLoaderContext = createContext({});
export const usePublicChildLoaderContext = (): TPublicChildLoaderContext => useContext(PublicChildLoaderContext);


type PropsType = {
  children: (props: TPublicChildLoaderContext) => JSX.Element
}
type PublicChildLoaderProps = (props: PropsType) => JSX.Element

const PublicChildLoader: PublicChildLoaderProps = ({ children }) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const { ready } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const validId = useMemo(() =>
    checkIfValidUUID(id), [id]);
  const show404UUID = !validId && id;
  const { failure: amlPublicFailure, fetching: amlPublicFetching } = useSelector(amlPublicSingleStateSelector);
  const { failure: publicAccountInfoFailure, fetching: publicAccountInfoFetching  }
    = useSelector(publicAccountInfoStateSelector);
  const { error: publicAccountTransactionsFailure }
    = useSelector(publicAccountTransactionsStateSelector);
  const { fetching: userInfoFetching } = useSelector(getUserInfoSelector);

  useEffect(() => {
    setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
  }, []);

  const show404 = Boolean(
    amlPublicFailure || publicAccountInfoFailure || publicAccountTransactionsFailure || show404UUID);
  const loading = useChainedLoading([
    initialLoading, amlPublicFetching, publicAccountInfoFetching, !ready, userInfoFetching]);

  const callBack = (): TPublicChildLoaderContext => ({
    show404, loading
  });

  return <PublicChildLoaderContext.Provider value={{ loading, show404 }}>
    {children(callBack())}
  </PublicChildLoaderContext.Provider>;
  
};

export default PublicChildLoader;