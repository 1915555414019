import { FC } from 'react';
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { EQueries, ECurrency } from "config/types";

import BNInput from "components/BNInput";
import Button from "components/Buttons/Button";

import useQuery from "hooks/useQuery";

import { EServiceCode, TPaymentCalculateOptions } from "store/services/types";
import {
  paymentCalculateDataSelector,
  paymentCalculateFailureSelector,
  paymentCalculateFetchingSelector,
  servicesAllSelectByCodeSelector
} from "store/services/selectors";
import { paymentPromoCodeApplyRequest } from "store/services/actions";

import { formatWithCurrencies } from "utils/fractions";

export type TPriceActivatePromoCodeFormProps = {
  currency: ECurrency;
}

const PriceActivatePromoCodeForm: FC<TPriceActivatePromoCodeFormProps> = ({ currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calculateData = useSelector(paymentCalculateDataSelector);
  const parsedErrors = useSelector(paymentCalculateFailureSelector)?.parsedErrors;
  const loading = useSelector(paymentCalculateFetchingSelector);
  const query = useQuery();
  const plan = query.get(EQueries.code) as (EServiceCode|undefined);
  const queryPlan = query.get(EQueries.code) as EServiceCode;
  const service = useSelector(servicesAllSelectByCodeSelector(queryPlan));
  const initialValues: TPaymentCalculateOptions = {
    service_code: plan,
  };

  const onSubmit = (values: TPaymentCalculateOptions) => {
    dispatch(paymentPromoCodeApplyRequest({ ...values, currency }));
  };

  const formPrices = () => {
    if (!calculateData) {
      return <span className="service-modal-promo-form_prices_actual">{
        formatWithCurrencies(service?.price, service?.currency || ECurrency.rub)}</span>;
    }

    if (calculateData.original_price === calculateData.price) {
      return <span className="service-modal-promo-form_prices_actual">{
        formatWithCurrencies(calculateData.original_price, calculateData?.currency)}</span>;
    }

    return (
      <span>
        <span className="service-modal-promo-form_prices_not-relevant">
          {formatWithCurrencies(calculateData?.original_price, calculateData?.currency)}
        </span>
        <span className="service-modal-promo-form_prices_actual">{
          formatWithCurrencies(calculateData?.price, calculateData?.currency)}
        </span>
      </span>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ setFieldValue, values }) => (
        <Form className="pricing__activate-promo-code-form">
          <div className="pricing__activate-promo-code-form__content">
            <BNInput
              type="text"
              name="promo_code"
              wrapperClass="pricing__activate-promo-code-form__input"
              label={t("naming.promoCode")}
              error={parsedErrors?.promo_code}
              disabled={loading}
              onChange={e => {
                const value = e.target.value || "";
                setFieldValue('promo_code', value.toLocaleUpperCase());
              }}
              value={values.promo_code}
            />
            <Button
              htmlType="submit"
              transparent={true}
              disabled={loading}
              title={t("action.apply")}
              className="pricing__activate-promo-code-form__btn"
            />
          </div>
          <div className="pricing__activate-promo-code-form__prices">
            {t("naming.outcome")}:
            <span className="pricing__activate-promo-code-form__prices__actual">{formPrices()}</span>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PriceActivatePromoCodeForm;
