import { FC, SVGProps } from "react";

{/* eslint-disable max-len */}
const SharpDownloadIcon: FC<SVGProps<SVGSVGElement>> = ({ onClick, className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className}>
    <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM11 10V6H13V10H16L12 14L8 10H11ZM17 17H7V15H17V17Z" fill="#748ADA"/>
  </svg>
);

export default SharpDownloadIcon;
