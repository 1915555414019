import { FC, useMemo } from "react";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import HeaderBack from "../HeaderBack";
import HeaderChecksLeft from "../HeaderChecksLeft";

const HeaderLeft: FC = () => {
  const { routePath } = useLayoutAppNameContext();
  
  const vectorBack: Record<string, { title: string, call: () => void }> = useMemo(() => ({
  }), []);

  return <div className="new-header__left">
    {routePath && vectorBack[routePath]
      ? <HeaderBack goBack={vectorBack[routePath].call} title={vectorBack[routePath].title} />
      : <HeaderChecksLeft/>}
  </div>;
};

export default HeaderLeft;