import { FC } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import SharpDownloadIcon from "../Icons/SharpDownloadIcon";

type DownloadProps = {
  onClick: () => void
  className?: string
}
const Download: FC<DownloadProps> = ({ onClick, className }) => {
  const { t } = useTranslation();

  return <div className={cn("download", className)} onClick={onClick} >
    <SharpDownloadIcon className="download__icon"/>
    {t("naming.download")}
  </div>;
};

export default Download;