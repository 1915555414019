import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getAMLSingleId, getAMLSinglePath, paths } from "config/paths";

import VectorIcon from "components/Icons/VectorIcon";
import { useLayoutAppNameContext } from "components/Layout/hooks";

import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";
import { EAmlSingleIds } from "store/aml/types";
import { setMobileAdditionalMenu } from "store/common/reducers";

const MobileHeaderLeft: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { routePath } = useLayoutAppNameContext();
  const amlSingleId = getAMLSingleId();
  const isArchived = amlSingleId === EAmlSingleIds.archived;
  const isManual = amlSingleId === EAmlSingleIds.manual;

  const vectorBack: Record<string, () => void> = useMemo(() => ({
    [paths.SINGLE_ASSET]: () => {
      history.push(paths.ASSETS);
    },
    [paths.SINGLE_AML]: () => {
      history.push(paths.AML + window.location.search);
    },
    [paths.SINGLE_AML_HISTORY]: () => {
      if (!amlSingleId) return;

      if (isArchived) {
        dispatch(analyticEvent(EEventType.AML_ARCHIVE_HISTORY_HIDE));
      } else if (isManual) {
        dispatch(analyticEvent(EEventType.AML_MANUAL_HISTORY_HIDE));
      } else {
        dispatch(analyticEvent(EEventType.AML_ACCOUNT_HISTORY_HIDE));
      }
      history.push(getAMLSinglePath(amlSingleId + window.location.search));
    },
    [paths.TAX_REPORT_FORMED]: () => {
      history.push(paths.DOCUMENTS);
    },
    [paths.DOCUMENTS_TRANSACTION_EXPORT_FORMED]: () => {
      history.push(paths.DOCUMENTS);
    },
    [paths.DOCUMENTS_SOURCES_EXPORT_FORMED]: () => {
      history.push(paths.DOCUMENTS);
    },
    [paths.SETTINGS]: () => {
      dispatch(setMobileAdditionalMenu(true));
    },
    [paths.SETTINGS_PLAN_AND_PAYMENTS]: () => {
      dispatch(setMobileAdditionalMenu(true));
    },
    [paths.SETTINGS_SAFETY]: () => {
      dispatch(setMobileAdditionalMenu(true));
    },
    [paths.KYT_API_KEYS]: () => {
      dispatch(setMobileAdditionalMenu(true));
    },
  }), [amlSingleId, isArchived, isManual]);

  const handleClickBack = () => {
    if (!routePath) return;

    const call = vectorBack[routePath];
    if (call) call();
  };

  const showVectorBack = Object.keys(vectorBack).includes(routePath as paths);
  return (
    <div className="header-mobile__left">
      {showVectorBack && <VectorIcon className="header-mobile__left__back" onClick={handleClickBack}/>}
    </div>
  );
};

export default MobileHeaderLeft;
