import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { paths } from "config/paths";
import { maxMobileMediaWidth } from "config/constants";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import MobileHeaderLeft from "./components/MobileHeaderLeft";
import MobileHeaderRight from "./components/MobileHeaderRight";
import MobileHeaderTitle from "./components/MobileHeaderTitle";

const MobileHeader: FC = () => {
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const location = useLocation();
  const { routePath } = useLayoutAppNameContext();

  const hideHeaderMobile = useMemo(() => {
    const hideHeaderPages: string[] = [
      paths.TAX_REPORT,
      paths.TAX_REPORT_OPERATIONS,
      paths.UNIFIED_TAX_REPORT_CREATE,
      paths.UNIFIED_TAX_REPORT,
      paths.DOCUMENTS_CREATE_TRANSACTION_EXPORT,
      paths.DOCUMENTS_TRANSACTION_EXPORT,
      paths.DOCUMENTS_CREATE_SOURCES_EXPORT,
      paths.DOCUMENTS_SOURCES_EXPORT,
    ];
    return hideHeaderPages.includes(routePath as paths);
  }, [location]);

  if (hideHeaderMobile && isMaxMobile) return null;

  return (
    <div className="header-mobile__wrapper container mob-p-16">
      <MobileHeaderLeft/>
      <div className="header-mobile">
        <MobileHeaderTitle/>
      </div>
      <MobileHeaderRight/>
    </div>
  );
};

export default MobileHeader;
