import { OutputSelector } from "reselect";

import { ECurrency, TNullable } from "config/types";

import { IApplicationState, TRequestHandler } from "../rootInterface";

export enum ESettingsServicesStatus {
  available = 'available',
  soon = 'soon',
  archived = 'archived',
}

export enum EServiceType {
  feature= "feature",
  tariffPlan= "tariff_plan",
}
export enum EServiceSet {
  amlCheck= "aml_checks",
}

export enum ETariffPlan {
  free = "free",
  smart = "smart",
  pro = "pro",
  corp = "corp",
}

export enum EServiceCode {
  taxReport2021 = "tax_report_2021",
  taxReport2022 = "tax_report_2022",
  taxReport2023 = "tax_report_2023",
  plansFree = "plans:free",
  plansSmartMonth = "plans:smart:month",
  plansSmartYear = "plans:smart:year",
  plansProMonth = "plans:pro:month",
  plansProYear = "plans:pro:year",
  amlPacks1="aml:packs:1",
  amlPacks5="aml:packs:5",
  amlPacks10="aml:packs:10",
}

export const AmlPacksCount = {
  [EServiceCode.amlPacks1]: 1,
  [EServiceCode.amlPacks5]: 5,
  [EServiceCode.amlPacks10]: 10,
};

export type TServicesData = {
  id: string;
  code: EServiceCode;
  type: EServiceType;
  name: string;
  state: ESettingsServicesStatus;
  currency: ECurrency;
  price: number;
  monthly_payment: TNullable<number>
  connected: boolean;
};

export type TServicesState = TRequestHandler<TServicesData[]>;

export type TServiceDetails = {
  id: string
  code: EServiceCode
  type: EServiceType
  name: string
  state: ESettingsServicesStatus
  currency: ECurrency
  price: number
  monthly_payment: TNullable<number>
  connected: boolean
  variants: TServicesData[]
}
export type TServiceDetailsState = TRequestHandler<TServiceDetails>

export type TPaymentCalculateOptions = {
  currency?: string;
  service_code?: EServiceCode;
  service_id?: string;
  promo_code?: string;
};

export type TPaymentOptions = {
  currency: string;
  amount: string;
  new_method?: string;
  save_method?: boolean;
  saved_method?: string;
  receipt_destination?: TNullable<string>;
  url_completed?: string;
  url_canceled?: string;
  url_pending?: string;
} & TPaymentCalculateOptions;

export type TPaymentCalculateData = {
  price: number;
  original_price: number;
  promo_code_applied: boolean;
  currency: ECurrency;
};

export enum PaymentRequestStatus {
  pending="pending",
  completed="completed",
  canceled="canceled",
}
export type TPaymentData = {
  id: string;
  amount: string;
  currency: ECurrency;
  payment_url: string;
  status: PaymentRequestStatus;
};

export type TPaymentState = TRequestHandler<TPaymentData>;

export type TPaymentCalculateState = { options?: TPaymentCalculateOptions } & TRequestHandler<TPaymentCalculateData>;

export type TServicesAllData = TServicesData[]
export type TServicesAllOptions = {
  params: {
    currency?: ECurrency
    type?: EServiceType
    set?: EServiceSet
  }
}
export type TServicesAllState = TRequestHandler<TServicesAllData>

export type TUserPaymentMethodStateState = TRequestHandler<TPaymentData>

export type TServicesStoreState = {
  servicesState: TServicesState;
  serviceDetailsState: TServiceDetailsState;
  paymentState: TPaymentState;
  paymentCalculateState: TPaymentCalculateState;
  userPaymentMethodState: TUserPaymentMethodStateState;
  servicesAllState: TServicesAllState;
  paymentPricingLoading: boolean;
};

export type TServicesOutputSelector<T> = OutputSelector<IApplicationState, T, (s: TServicesStoreState) => T>;
