import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

import { AmlRiskModel, TAmlOutputSelector, TAmlSingleChecksState, TAmlSingleStateState } from "./types";

const selectState = (state: IApplicationState) => state.aml;

export const amlSingleStateSelector = (id: string | undefined): TAmlOutputSelector<TAmlSingleStateState|undefined> =>
  createSelector(selectState, aml =>
    id ? aml?.amlSingleStateState[id] && aml?.amlSingleStateState[id] : undefined
  );

export const amlSingleCheckSelector = createSelector(
  selectState, aml => aml.amlSingleCheckState);

export const amlSingleChecksSelector = (id: string | undefined): TAmlOutputSelector<TAmlSingleChecksState|undefined> =>
  createSelector(
    selectState, aml => {
      if (!id) return undefined;
      return aml?.amlSingleChecksState[id];
    }
  );

export const amlStateSelector = createSelector(
  selectState, aml => aml.amlStateState);
export const amlCheckManuallyStateSelector = createSelector(
  selectState, aml => aml.amlCheckManuallyState);
export const amlRiskSummaryStateSelector = createSelector(
  selectState, aml => aml.amlRiskSummaryState);

export const amlActivateDropdownSelector = createSelector(
  selectState, aml => aml.amlActivateDropdown);
export const amlPublicSingleStateSelector = createSelector(
  selectState, aml => aml.amlPublicSingleState);
export const amlRisksModelsSelector = createSelector(
  selectState, aml => aml.amlRisksModelsState);
export const amlRisksModelSelector = (id: string | undefined | null): TAmlOutputSelector<AmlRiskModel|undefined> =>
  createSelector(
    selectState, aml => {
      if (!id) return undefined;
      return aml?.amlRisksModelsState.data?.find(item => item.id === id);
    }
  );

export const amlRiskModelInfoSelector = createSelector(
  selectState, aml => aml.amlRiskModelInfoState);
export const amlRisksModelCreateSelector = createSelector(
  selectState, aml => aml.amlRisksModelCreateState);
export const amlRisksModelActivateSelector = createSelector(
  selectState, aml => aml.amlRisksModelActivateState);
export const amlRisksModelDeleteSelector = createSelector(
  selectState, aml => aml.amlRisksModelDeleteState);
export const amlRisksModelRulesSelector = createSelector(
  selectState, aml => aml.amlRisksModelRulesState);
export const amlRisksEntityCategoriesSelector = createSelector(
  selectState, aml => aml.amlRisksEntityCategoriesState);
export const amlRisksModelEditSelector = createSelector(
  selectState, aml => aml.amlRisksModelEditState);
export const amlRisksModelUpdateRulesSelector = createSelector(
  selectState, aml => aml.amlRisksModelUpdateRulesState);
