import { EAmlSingleIds } from "./types";

export const formAmlSingleChecksId = (
  { account, archived, manual }: {account?: string, archived?: boolean, manual?: boolean}
): string|undefined => {
  if (archived) {
    return EAmlSingleIds.archived;
  } else if (manual) {
    return EAmlSingleIds.manual;
  }
  return account;
};