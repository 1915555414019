import { FC } from "react";
import { useSelector } from "react-redux";

import { getAssetSingleId } from "config/paths";

import AddressCopyMenuItem from "components/AddressCopyMenuItem";

import { getSingleAssetDataSelector } from "store/assets/selectors";

import { TAssetSingleMenuItemProps } from "../../types";


const AssetSingleMenuCopyAddress: FC<TAssetSingleMenuItemProps> = ({ setActive }) => {
  const id = getAssetSingleId();
  const singleAsset = useSelector(getSingleAssetDataSelector(id));

  if (!singleAsset?.address) return null;

  return (
    <AddressCopyMenuItem address={singleAsset?.address} onClick={() => setActive(false)}/>
  );
};

export default AssetSingleMenuCopyAddress;
