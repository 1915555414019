import React, { FC, ReactElement, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import { maxMobileMediaWidth } from "config/constants";
import { getAMLSingleId, paths } from "config/paths";

import BNDropdown from "components/BNDropdown";

import { ELimitations, usePlanLimitation } from "hooks/usePlanLimitation";

import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";
import { setAmlActivateDropdown } from "store/aml/reducers";
import { amlActivateDropdownSelector, amlStateSelector } from "store/aml/selectors";
import { getAMLSingleAssetsSelector } from "store/assets/selectors";

import AMLActivatePacks from "../AMLActivatePacks";

export const CHECK_AML_BUTTON_ID = "check-for-aml";

const AMLActivateDropdown: FC<{ className?: string }> = ({ children, className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const { openUpgradePlan } = usePlanLimitation({
    limitType: ELimitations.directToPlan,
  });
  const { data: amlState } = useSelector(amlStateSelector);
  const amlActivateDropdown = useSelector(amlActivateDropdownSelector);
  const available = process.env.REACT_APP_AML_ACTIVATE_DISABLED !== "true";
  const checkAMLButton = useRef<HTMLDivElement|null>(null);
  const amlSingleId = getAMLSingleId();
  const amlAsset = useSelector(getAMLSingleAssetsSelector(amlSingleId));

  const overlay = () => (isMaxMobile ? <></> : <AMLActivatePacks/>);

  const handleClick = () => {
    if (!available) {
      if (amlState?.available_checks) {
        history.push(paths.AML);
      } else {
        openUpgradePlan();
      }
    } else if (isMaxMobile) {
      dispatch(openModal(EModals.amlActivate));
    }
  };

  useEffect(() => {
    if (!checkAMLButton.current) {
      const element = document.getElementById(CHECK_AML_BUTTON_ID) as HTMLDivElement;
      if (element) {
        checkAMLButton.current = element;
      }
    }
  }, [amlAsset]);

  return (
    <BNDropdown
      overlay={overlay}
      className={cn("aml-activate-dropdown", className)}
      active={(available && !isMaxMobile) && amlActivateDropdown}
      setActive={$active => dispatch(setAmlActivateDropdown($active))}
      onClick={handleClick}
      outsideClickIgnore={checkAMLButton.current ? [checkAMLButton] : []}
    >
      {({ active }) =>
        <>
          {children && React.cloneElement(children as ReactElement,
            { className: cn((children as ReactElement).props.className, { "active": active }), })}
        </>
      }
    </BNDropdown>
  );
};

export default AMLActivateDropdown;
