import { FC } from "react";
import { Menu as AntMenu } from "antd";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

import { NAVIGATION_LINKS } from "config/constants";
import { paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import { dashboardOnboardingSelector } from "store/common/selectors";


import { useActiveNavLink } from "../../../NewHeader/hooks";

import SidebarAssets from "../SidebarAssets";
import SidebarAMLRisk from "../SidebarAMLRisk";
import SidebarBottom from "../SidebarBottom";

const SidebarBody: FC = () => {
  const { t } = useTranslation();
  const { logo } = useLayoutAppNameContext();
  const isActiveLink = useActiveNavLink();
  const { target, isRun } = useSelector(dashboardOnboardingSelector);

  const isActive = (to: paths, id: string) => {
    if (!target?.includes(id) && !isRun) return isActiveLink(to);
    return Boolean(target?.includes(id));
  };

  return  (
    <PerfectScrollbar
      options={{ suppressScrollX: true }}
    >
      <NavLink to={paths.HOME} exact={true} className="sidebar__logo">
        {logo}
      </NavLink>
      <AntMenu
        mode="inline"
        className="sidebar__nav"
      >
        {NAVIGATION_LINKS.filter(link => link.active).map(link => {
          switch (link.to) {
          case paths.ASSETS:
            return <SidebarAssets key={link.id} title={link.title}/>;
          case paths.AML:
            return <SidebarAMLRisk key={link.id} title={link.title}/>;
          }
          return (
            <li key={link.id} className={cn("sidebar-nav-item", { active: isActive(link.to, link.id) })}>
              <NavLink
                isActive={() => isActive(link.to, link.id)}
                to={link.to}
                exact={true}
                className="sidebar-nav-item-link"
                id={link.id}
                activeClassName="sidebar-nav-item-link__active"
              >
                {t(link.title)}
              </NavLink>
            </li>
          );
        })}
      </AntMenu>
      <SidebarBottom/>
    </PerfectScrollbar>
  );
};

export default SidebarBody;
