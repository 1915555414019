import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import { setMobileAdditionalMenu } from "store/common/reducers";


import { useActiveNavLink } from "../NewHeader/hooks";

import { MOBILE_MENU } from "./container";
import MobileTabAdditionalMenu from "./components/MobileTabAdditionalMenu";

const MobileTabMenu: FC = () => {
  const dispatch = useDispatch();
  const isActive = useActiveNavLink();
  const { routePath } = useLayoutAppNameContext();
  const hideMenu = [
    paths.SINGLE_ASSET,
    paths.SINGLE_AML,
    paths.SINGLE_AML_HISTORY,
    paths.TAX_REPORT_FORMED,
    paths.DOCUMENTS_TRANSACTION_EXPORT_FORMED,
    paths.DOCUMENTS_TRANSACTION_EXPORT_FORMED,
    paths.DOCUMENTS_SOURCES_EXPORT_FORMED,
  ].includes(routePath as paths);

  if (hideMenu) return null;

  return <div className="mobile-tab-menu">
    {MOBILE_MENU.map(link => (
      <div className="mobile-tab-menu__item" key={link.id}>
        <NavLink
          to={link.to}
          exact={true}
          className="mobile-tab-menu__link"
          activeClassName="active"
          isActive={() => isActive(link.to)}
          onClick={() => dispatch(setMobileAdditionalMenu(false))}
        >
          {link.icon && <link.icon />}
        </NavLink>
      </div>)
    )}
    <MobileTabAdditionalMenu/>
  </div>;
};

export default MobileTabMenu;
