import i18n from "../../../../i18n";

import { TFeatures } from "../PricingCardFeatures";

export const availableFeatures = (): TFeatures => [
  [
    {
      title: i18n.t("pricingFeatures.portfolioTracker"),
      checked: true
    },
    {
      title: i18n.t("pricingFeatures.unlimitedAPIs"),
      subTitle: null,
      checked: true
    },
    {
      title: i18n.t("pricingFeatures.wallets10kTransactions"),
      subTitle: null,
      checked: true
    },
    {
      title: i18n.t("pricing.notesAndTags"),
      subTitle: i18n.t("pricingFeatures.nOLimits"),
      checked: true
    },
    {
      title: i18n.t("pricingFeatures.proofDocumentStorage"),
      subTitle: i18n.t("pricingFeatures.numberMB", { number: 1000 }),
      checked: true
    }
  ],
  [
    {
      title: i18n.t("pricingFeatures.amlControl"),
      checked: true,
      pink: true,
    },
    {
      title: i18n.t("pricingFeatures.amlChecks"),
      subTitle: i18n.t("pricingFeatures.pcsPerMonth", { number: 30 }),
      checked: true,
      pink: true,
    },
  ],
  [
    {
      title: i18n.t("pricingFeatures.taxForms"),
      subTitle: i18n.t("pricingFeatures.numberTransactionsCurrentYear", { number: 1000 }),
      checked: true,
    },
    {
      title: i18n.t("pricingFeatures.proofOfSourceOfFunds"),
      checked: true
    },
    {
      title: i18n.t("pricingFeatures.transactionsExport"),
      subTitle: i18n.t("pricingFeatures.nOLimits"),
      checked: true,
    },
    {
      title: i18n.t("pricingFeatures.advancedCryptoDocuments"),
      subTitle: i18n.t("pricingFeatures.soon"),
      pink: true,
      checked: true,
    }
  ],
  [
    {
      title: i18n.t("pricingFeatures.oKmanBotAndOnlineChat"),
      checked: true
    },
    {
      title: i18n.t("pricingFeatures.academy"),
      subTitle: i18n.t("pricingFeatures.soon"),
      checked: true
    },
    {
      title: i18n.t("pricingFeatures.emailSupport"),
      checked: true,
      pink: true
    }
  ]
];
