import { FC } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { paths } from "config/paths";

import PreparedMobileDrawer from "components/PreparedMobileDrawer";
import { useLayoutAppNameContext } from "components/Layout/hooks";
import {
  MOBILE_SETTINGS_LINKS,
} from "components/SettingsPageWrapper/components/SettingsSidebar/constants";

import { getUserInfoSelector, userPlanInfoSelector } from "store/user/selectors";

import { getCurrentPlan } from "utils/plan";

export type TMobileTabDrawerProps = {
  visible: boolean
  close: () => void;
}
const MobileTabDrawer: FC<TMobileTabDrawerProps> = ({ visible, close  }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { logo } = useLayoutAppNameContext();
  const { data: userInfo } = useSelector(getUserInfoSelector);
  const { data: userPlanInfo } = useSelector(userPlanInfoSelector);

  const handleClickPlan = () => {
    history.push(paths.PRICING);
  };

  return (
    <PreparedMobileDrawer
      visible={visible}
      close={close}
      hideHeader={true}
      className="mobile-tab-menu__drawer"
    >
      <div className="mobile-tab-menu__drawer__content content">
        <div className="mobile-tab-menu__drawer__header">
          <div className="mobile-tab-menu__drawer__logo">
            {logo}
          </div>
          <div className="mobile-tab-menu__drawer__username">
            {userInfo?.username || userInfo?.email || userInfo?.phone}
          </div>
          <div className="mobile-tab-menu__drawer__current-plan">
            {t("upgradePlan.currentPlan")}:
            <span onClick={handleClickPlan}>{getCurrentPlan(userPlanInfo?.current_plan.tariff_plan)}</span>
          </div>
        </div>
        <div className="mobile-tab-menu__drawer__links">
          {
            MOBILE_SETTINGS_LINKS.filter(link => link.active).map(link => (
              <NavLink
                key={link.id}
                to={link.to}
                className="mobile-tab-menu__drawer__link"
                exact={true}
                onClick={close}
              >
                {link.icon && <link.icon />}
                <span>{t(link.title)}</span>
              </NavLink>
            ))
          }
        </div>
      </div>
    </PreparedMobileDrawer>
  );
};

export default MobileTabDrawer;
