export enum paths {
  HOME = "/",
  AUTH_OAUTH_GET_CODE = "/auth/oauth/get-code",
  AUTH_OAUTH_CALLBACK = "/auth/oauth/callback",
  SIGN_IN = "/login",
  SIGN_UP = "/registration",
  FORGOT = "/reset-password",
  CONFIRM_EMAIL = "/confirm-email",
  PROFILE = "/profile",
  ASSETS = "/assets",
  TRANSACTIONS = "/transactions",
  SINGLE_ASSET = "/assets/:id",
  SETTINGS = "/settings",
  SETTINGS_SAFETY = "/settings/safety",
  SETTINGS_SERVICES = "/settings/services",
  SETTINGS_REPORTS = "/settings/reports",
  SETTINGS_OTHER = "/settings/other",
  SETTINGS_PLAN_AND_PAYMENTS = "/settings/plan-and-payments",

  KYT = "/kyt",
  KYT_API_KEYS = "/kyt/api-keys",

  REPORTS = "/reports",
  DOCUMENTS = "/documents",

  TAX_REPORT_CREATE = "/documents/tax-report",
  TAX_REPORT = "/documents/tax-report/:id",
  TAX_REPORT_FORMED = "/documents/tax-report/:id/formed",
  TAX_REPORT_OPERATIONS = "/documents/tax-report/:id/operations",

  UNIFIED_TAX_REPORT_CREATE = "/documents/unified-tax-report",
  UNIFIED_TAX_REPORT = "/documents/unified-tax-report/:id",
  UNIFIED_TAX_REPORT_FORMED = "/documents/unified-tax-report/:id/formed",

  DOCUMENTS_CREATE_TRANSACTION_EXPORT = "/documents/transaction-export",
  DOCUMENTS_TRANSACTION_EXPORT = "/documents/transaction-export/:id",
  DOCUMENTS_TRANSACTION_EXPORT_FORMED = "/documents/transaction-export/:id/formed",

  DOCUMENTS_CREATE_SOURCES_EXPORT = "/documents/sources-export",
  DOCUMENTS_SOURCES_EXPORT = "/documents/sources-export/:id",
  DOCUMENTS_SOURCES_EXPORT_FORMED = "/documents/sources-export/:id/formed",

  PRICING = "/pricing",
  AML = "/aml",
  SINGLE_AML = "/aml/:id",
  SINGLE_AML_HISTORY = "/aml/:id/history/:historyId",
  TRADING_INSIGHT = "/trading-insight",

  PUBLIC_AML = "/public/aml-report/:id",
  PUBLIC_ACCOUNT = "/public/account/:id",
  PUBLIC_ACCOUNT_TRANSACTIONS = "/public/account/:id/transactions",
}
export const getAssetsSinglePath = (id: string): string => paths.SINGLE_ASSET.replace(":id", id);
export const getTaxReportSinglePath = (id: string): string => paths.TAX_REPORT.replace(":id", id);
export const getTaxReportFormedPath = (id: string): string => paths.TAX_REPORT_FORMED.replace(":id", id);
export const getReportOperationsPath = (id: string): string => paths.TAX_REPORT_OPERATIONS.replace(":id", id);
export const getUnifiedTaxReportPath = (id: string): string => paths.UNIFIED_TAX_REPORT.replace(":id", id);
export const getUnifiedTaxReportFormedPath = (id: string): string => paths.UNIFIED_TAX_REPORT_FORMED.replace(":id", id);
export const getDocumentTransactionExportPage = (id: string): string =>
  paths.DOCUMENTS_TRANSACTION_EXPORT.replace(":id", id);
export const getDocumentFormedTransactionExportPage = (id: string): string =>
  paths.DOCUMENTS_TRANSACTION_EXPORT_FORMED.replace(":id", id);
export const getDocumentSourcesExportPage = (id: string): string =>
  paths.DOCUMENTS_SOURCES_EXPORT.replace(":id", id);
export const getDocumentFormedSourcesExportPage = (id: string): string =>
  paths.DOCUMENTS_SOURCES_EXPORT_FORMED.replace(":id", id);
export const getAMLSinglePath = (id: string): string =>
  paths.SINGLE_AML.replace(":id", id);
export const getAMLSingleHistoryPath = (id: string, historyId: string): string =>
  paths.SINGLE_AML_HISTORY.replace(":id", id).replace(":historyId", historyId);
export const getAMLPublicPath = (id: string): string =>
  paths.PUBLIC_AML.replace(":id", id);
export const getAccountPublicPath = (id: string): string =>
  paths.PUBLIC_ACCOUNT.replace(":id", id);
export const getAccountPublicTransactionsPath = (id: string): string =>
  paths.PUBLIC_ACCOUNT_TRANSACTIONS.replace(":id", id);

export const findIdFromUrl = (startFrom: string|undefined = "/"): string|undefined => {
  const matches = window.location.pathname.match(new RegExp(`${startFrom}(.*?(?=\\/|$))`));
  if (matches) { // Did it match?
    return matches[1];
  }
};
export const getAssetSingleId = ():string|undefined => findIdFromUrl("/assets/");
export const getAMLSingleId = ():string|undefined => findIdFromUrl("/aml/");
export const getAMLHistoryId = ():string|undefined => findIdFromUrl("/history/");
export const getReportId = ():string|undefined => {
  let url = findIdFromUrl("/documents/tax-report/");
  if (!url) {
    url = findIdFromUrl("/documents/unified-tax-report/");
  }
  if (!url) {
    url = findIdFromUrl("/documents/transaction-export/");
  }
  if (!url) {
    url = findIdFromUrl("/documents/sources-export/");
  }
  return url;
};
export const getPublicAccountId = ():string|undefined => findIdFromUrl("/public/account/");
export const getPublicAmlReportId = ():string|undefined => findIdFromUrl("/public/aml-report/");
