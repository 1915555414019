import { FC } from "react";
import { useSelector } from "react-redux";

import { getAMLSingleId } from "config/paths";

import BNDropdown from "components/BNDropdown";
import { TBNDropdownChildren } from "components/BNDropdown/types";
import EllipsisHorizontalIcon from "components/Icons/EllipsisHorizontalIcon";
import AddressCopyMenuItem from "components/AddressCopyMenuItem";

import { getSingleAssetDataSelector } from "store/assets/selectors";

const AmlSingleMenu: FC = () => {
  const id = getAMLSingleId();
  const singleAsset = useSelector(getSingleAssetDataSelector(id));

  if (!singleAsset?.address) return null;

  const overlay = ({ setActive }: TBNDropdownChildren) => (
    <>
      {singleAsset.address &&
        <AddressCopyMenuItem key={"1"} address={singleAsset.address} onClick={() => setActive(false)}/>}
    </>
  );

  return (
    <BNDropdown
      overlay={overlay}
      className="other-navigation assets-single__header_menu"
    >
      {() => (<EllipsisHorizontalIcon />)}
    </BNDropdown>
  );
};

export default AmlSingleMenu;
