import { FC } from "react";
import { useSelector } from "react-redux";

import { getAssetSingleId } from "config/paths";

import { getSyncDate } from "components/AssetsSinglePage/components/AssetsSingleSubHeader/utils";

import {
  getSingleAssetDataSelector,
  startAccountSyncFailureSelector,
  startAccountSyncLoadingSelector
} from "store/assets/selectors";

const MobileHeaderAssetSingleTitle: FC = () => {
  const assetsSingleId = getAssetSingleId();
  const singleAssetData = useSelector(getSingleAssetDataSelector(assetsSingleId));
  const loading = useSelector(startAccountSyncLoadingSelector(assetsSingleId));
  const failureData = useSelector(startAccountSyncFailureSelector(assetsSingleId || ""));
  const error = failureData?.config?.url?.includes(assetsSingleId || "");

  return (
    <>
      <span>{singleAssetData?.name}</span>
      <div className="header-mobile__sub-title">
        {getSyncDate(loading, error, singleAssetData?.status, singleAssetData?.sync_max_time)}
      </div>
    </>
  );
};

export default MobileHeaderAssetSingleTitle;
