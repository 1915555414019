import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

import { EServiceCode, TServicesData, TServicesOutputSelector } from "./types";

const selectState = (state: IApplicationState) => state.services;

export const servicesDataSelector = createSelector(selectState, state => state.servicesState.data);
export const servicesFetchingSelector = createSelector(selectState, state => state.servicesState.fetching);

export const servicesAllSelector = createSelector(selectState, state => state.servicesAllState);
// export const servicesExistsSelector: TServicesOutputSelector<Record<[K in EServiceCode], boolean>>
export const servicesExistsSelector: TServicesOutputSelector<{ [K in EServiceCode]?: boolean; }>
  = createSelector(selectState, state =>
    state.servicesAllState.data?.reduce(
      (accumulator, currentValue) =>
        ({ ...accumulator,  [currentValue.code]: true }),
      {}
    ) || {});
export const servicesAllSelectByCodeSelector =
  (code: EServiceCode): TServicesOutputSelector<TServicesData|null|undefined> =>
    createSelector(selectState, state => state.servicesAllState?.data
      ? state.servicesAllState?.data.find(item => item.code === code)
      : null);
      
export const serviceDetailsSelector = createSelector(selectState, state => state.serviceDetailsState);

export const paymentCalculateDataSelector = createSelector(selectState, state => state.paymentCalculateState.data);
export const paymentCalculateFailureSelector = createSelector(
  selectState,
  state => state.paymentCalculateState.failure
);
export const paymentCalculateFetchingSelector = createSelector(
  selectState,
  state => state.paymentCalculateState.fetching
);
export const paymentCalculateOptionsSelector = createSelector(
  selectState,
  state => state.paymentCalculateState.options
);

export const paymentFetchingSelector = createSelector(
  selectState,
  state => state.paymentState.fetching
);
export const paymentStateSelector = createSelector(
  selectState,
  state => state.paymentState
);

export const userPaymentMethodStateSelector = createSelector(
  selectState,
  state => state.userPaymentMethodState
);

export const activatePlanPendingSelector =  createSelector(
  selectState,
  state =>
    (
      state.paymentPricingLoading
      || state.paymentState.fetching
      || state.userPaymentMethodState.fetching
    )
);
