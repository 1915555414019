import i18n from "../../../../i18n";

import { TFeatures } from "../PricingCardFeatures";

export const availableFeatures = ():TFeatures => [
  [
    {
      title: i18n.t("pricingFeatures.everythingOfSmart"),
      checked: true,
      fat: true
    }
  ],
  [
    {
      title: i18n.t("pricingFeatures.taxForms"),
      subTitle: i18n.t("pricingFeatures.nOLimits"),
      checked: true,
    },
    {
      title: i18n.t("pricingFeatures.proofDocumentStorage"),
      subTitle: i18n.t("pricingFeatures.numberGB", { number: 5 }),
      checked: true,
    },
    {
      title: i18n.t("pricingFeatures.amlChecks"),
      subTitle: i18n.t("pricingFeatures.pcsPerMonth", { number: 60 }),
      checked: true,
    },
  ]
];
