import { useMediaQuery } from "react-responsive";
import { FC } from "react";
import { DownloadOutlined } from "@ant-design/icons";

import { maxMobileMediaWidth } from "config/constants";
import { getPublicAmlReportId } from "config/paths";

import Download from "components/Download";

const AMLPublicDownload: FC = () => {
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const id = getPublicAmlReportId();

  const onClick = () => {
    if (!process?.env?.REACT_APP_MAIN_API || !id) return;

    window.open(process?.env?.REACT_APP_MAIN_API + `/public/aml/report/${id}.pdf`, '_blank');
  };


  if (!process?.env?.REACT_APP_MAIN_API || !id) return null;

  if (isMaxMobile) return <DownloadOutlined onClick={onClick} className="aml-public__download__icon" />;

  return <Download onClick={onClick} className="aml-public__download"/>;
};

export default AMLPublicDownload;