import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import AMLActivate from "components/AMLActivate";

import { amlStateSelector } from "store/aml/selectors";
import { amlStateRequest } from "store/aml/reducers";

const HeaderChecksLeft: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: amlState, fetching } = useSelector(amlStateSelector);

  useEffect(() => {
    dispatch(amlStateRequest({}));
  }, [dispatch]);

  return (
    <AMLActivate.Dropdown>
      {(!fetching || amlState) && (
        <div className={cn("new-header__checks-left", { "no-checks": !amlState?.available_checks })}>
          <span>
            {amlState?.available_checks
              ? t("amlPage.checksLeft", { number: amlState?.available_checks })
              : t("amlPage.checksLeft", { number: 0 })
            }
          </span>
        </div>)}
    </AMLActivate.Dropdown>
  );
};

export default HeaderChecksLeft;
