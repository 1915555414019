import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import isFunction from "lodash/isFunction";

import request from "API";

import delay from "utils/delay";

import { IApplicationState, IPayloadAction } from "../rootInterface";
import { getMyAssetsSetSingleData } from "../assets/reducers";

import {
  amlCheckManuallyFailure,
  amlCheckManuallyRequest,
  amlCheckManuallySuccess,
  amlPublicSingleFailure,
  amlPublicSingleRequest,
  amlPublicSingleSuccess,
  amlRiskModelInfoFailure, amlRiskModelInfoRequest,
  amlRiskModelInfoSuccess, amlRisksEntityCategoriesFailure, amlRisksEntityCategoriesRequest,
  amlRisksEntityCategoriesSuccess, amlRisksModelActivateFailure, amlRisksModelActivateRequest,
  amlRisksModelActivateSuccess,
  amlRisksModelCreateFailure,
  amlRisksModelCreateRequest,
  amlRisksModelCreateSuccess,
  amlRisksModelDeleteFailure,
  amlRisksModelDeleteRequest,
  amlRisksModelDeleteSuccess, amlRisksModelEditFailure, amlRisksModelEditRequest,
  amlRisksModelEditSuccess,
  amlRisksModelRemove,
  amlRisksModelRulesFailure,
  amlRisksModelRulesRequest,
  amlRisksModelRulesSuccess,
  amlRisksModelsAppend,
  amlRisksModelSetActivate,
  amlRisksModelsFailure,
  amlRisksModelsRequest,
  amlRisksModelsSuccess,
  amlRisksModelUpdateRulesFailure,
  amlRisksModelUpdateRulesRequest,
  amlRisksModelUpdateRulesSuccess,
  amlRiskSummaryFailure,
  amlRiskSummaryRequest,
  amlRiskSummarySuccess,
  amlSingleCheckFailure,
  amlSingleCheckRequest,
  amlSingleChecksFailure,
  amlSingleChecksLoadMore,
  amlSingleChecksRequest,
  amlSingleChecksSuccess,
  amlSingleCheckSuccess,
  amlSingleStateFailure,
  amlSingleStateRequest,
  amlSingleStateSuccess,
  amlStateFailure,
  amlStateRequest,
  amlStateSuccess
} from "./reducers";
import {
  AmlRiskModel,
  AmlRiskModelInfoOptions, AmlRisksEntityCategoriesOptions,
  AmlRisksModelActivateOptions,
  AmlRisksModelCreateOptions,
  AmlRisksModelDeleteOptions,
  AmlRisksModelEditOptions,
  AmlRisksModelRulesOptions,
  AmlRisksModelUpdateRulesOptions,
  EAmlSingleStateCheckStatus,
  TAmlCheckManuallyData,
  TAmlSingleChecksOptions,
  TAmlSingleStateData,
  TAmlSingleStateState,
  TAmlStateData,
  TAmlStateManualCheckStatuses
} from "./types";
import { formAmlSingleChecksId } from "./utils";

function* getAmlSingleState(action: IPayloadAction<{ id: string, check?: boolean }>) {
  const { id, check } = action.payload;
  try {
    const response: AxiosResponse<TAmlSingleStateData> = yield call(request.get, `/aml/${id}/state/`);
    const prevState: TAmlSingleStateState | undefined
      = yield select((state: IApplicationState) => state.aml.amlSingleStateState[id]);

    if (!prevState?.data) {
      yield put(amlSingleStateSuccess({ id, data: response.data }));
    }

    if (response.data.check_status === EAmlSingleStateCheckStatus.pending) {
      yield delay(2000);
      yield put(amlSingleStateRequest({ id, check: true }));
    } else if (check) {
      yield put(amlSingleChecksRequest({ account: id }));
      yield put(getMyAssetsSetSingleData({
        id,
        data: { risk_score: response.data.risk_score }
      }));
    }
    yield put(amlSingleStateSuccess({ id, data: response.data }));
  } catch (e) {
    yield put(amlSingleStateFailure(e));
  }
}

function* amlSingleCheck(action: IPayloadAction<{ id: string }>) {
  try {
    const response: AxiosResponse = yield call(request.post, `/aml/${action.payload.id}/check/`);
    yield put(amlSingleCheckSuccess(response.data));
    yield put(amlSingleStateRequest({ id: action.payload.id, check: true }));
    yield put(amlStateRequest({}));
  } catch (e) {
    yield put(amlSingleCheckFailure({ id: action.payload.id, error: e }));
  }
}

function* amlSingleChecks(action: IPayloadAction<TAmlSingleChecksOptions>) {
  const { loadMore, account, manual, archived, ...rest } = action.payload;
  const id = formAmlSingleChecksId({ account, archived, manual });
  const params: TAmlSingleChecksOptions = rest;
  if (archived) {
    params.archived = true;
  } else if (manual) {
    params.manual = true;
  } else if (account){
    params.account = account;
  }

  try {
    const response: AxiosResponse = yield call(request.get, "/aml/checks/", { params });

    if (loadMore) {
      yield put(amlSingleChecksLoadMore({ id, data: response.data }));
    } else {
      yield put(amlSingleChecksSuccess({ id, data: response.data }));
    }
  } catch (e) {
    yield put(amlSingleChecksFailure({ id, error: e }));
  }
}

function* getAmlState(action: IPayloadAction<{ check?: boolean, callOnSuccess?: () => void }>) {
  const { check, callOnSuccess } = action.payload;

  try {
    const response: AxiosResponse<TAmlStateData> = yield call(request.get, "/aml/state/");
    yield put(amlStateSuccess(response.data));

    if (check && response.data.manual_check_status === TAmlStateManualCheckStatuses.checking) {
      yield delay(2000);
      yield put(amlStateRequest({ check: true, callOnSuccess }));
    } else {
      if (isFunction(callOnSuccess)) callOnSuccess();
    }
  } catch (e) {
    yield put(amlStateFailure(e));
  }
}

function* amlCheckManually(action: IPayloadAction<{ network: string, value: string, callOnSuccess: () => void }>) {
  try {
    const response: AxiosResponse<TAmlCheckManuallyData>
      = yield call(request.post, "/aml/check-manually/", action.payload);
    yield put(amlCheckManuallySuccess(response.data));

    if (response.data.status === EAmlSingleStateCheckStatus.pending) {
      yield put(amlStateRequest({ check: true, callOnSuccess: action.payload.callOnSuccess }));
    }
  } catch (e) {
    yield put(amlCheckManuallyFailure(e));
  }
}


function* getAmlRiskSummary() {
  try {
    const response: AxiosResponse = yield call(request.get, "/aml/risks-summary/");
    yield put(amlRiskSummarySuccess(response.data));
  } catch (error) {
    yield put(amlRiskSummaryFailure(error));
  }
}

function* getAmlPublicSingle(action: IPayloadAction<{ id: string }>) {
  try {
    const response: AxiosResponse = yield call(request.get, `/public/aml/report/${action.payload.id}/`);
    yield put(amlPublicSingleSuccess(response.data));
  } catch (error) {
    yield put(amlPublicSingleFailure(error));
  }
}

function* amlRisksModels() {
  try {
    const response: AxiosResponse = yield call(request.get, "/aml/risks/models/");
    yield put(amlRisksModelsSuccess(response.data));
  } catch (error) {
    yield put(amlRisksModelsFailure(error));
  }
}

function* amlRisksModelCreate(action: IPayloadAction<AmlRisksModelCreateOptions>) {
  const { data } = action.payload;
  try {
    const response: AxiosResponse<AmlRiskModel> = yield call(request.post, "/aml/risks/models/create/", data);
    yield put(amlRisksModelCreateSuccess(response.data));
    yield put(amlRisksModelsAppend(response.data));

    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(amlRisksModelCreateFailure(error));
  }
}

function* amlRiskModelInfo(action: IPayloadAction<AmlRiskModelInfoOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.get, `/aml/risks/models/${id}/info/`);
    yield put(amlRiskModelInfoSuccess(response.data));
  } catch (error) {
    yield put(amlRiskModelInfoFailure(error));
  }
}

function* amlRisksModelActivate(action: IPayloadAction<AmlRisksModelActivateOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.post, `/aml/risks/models/${id}/activate/`);
    yield put(amlRisksModelActivateSuccess(response.data));
    yield put(amlRisksModelSetActivate({ id }));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(amlRisksModelActivateFailure(error));
  }
}


function* amlRisksModelDelete(action: IPayloadAction<AmlRisksModelDeleteOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.delete, `/aml/risks/models/${id}/delete/`);
    yield put(amlRisksModelDeleteSuccess(response.data));
    yield put(amlRisksModelRemove({ id }));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(amlRisksModelDeleteFailure(error));
  }
}

function* amlRisksEntityCategories(action: IPayloadAction<AmlRisksEntityCategoriesOptions>) {
  try {
    const response: AxiosResponse = yield call(request.get, "/aml/risks/entity-categories/");
    yield put(amlRisksEntityCategoriesSuccess(response.data));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(amlRisksEntityCategoriesFailure(error));
  }
}

function* amlRisksModelEdit(action: IPayloadAction<AmlRisksModelEditOptions>) {
  const { id, data } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.patch, `/aml/risks/models/${id}/edit/`, data);
    yield put(amlRisksModelEditSuccess(response.data));
    yield put(amlRiskModelInfoSuccess(response.data));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(amlRisksModelEditFailure(error));
  }
}

function* amlRisksModelRules(action: IPayloadAction<AmlRisksModelRulesOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.get, `/aml/risks/models/${id}/rules/`);
    yield put(amlRisksModelRulesSuccess(response.data));
  } catch (error) {
    yield put(amlRisksModelRulesFailure(error));
  }
}

function* amlRisksModelUpdateRules(action: IPayloadAction<AmlRisksModelUpdateRulesOptions>) {
  const { id, data } = action.payload;
  try {
    const response: AxiosResponse<AmlRiskModel> =
      yield call(request.post, `/aml/risks/models/${id}/update-rules/`, data);
    yield put(amlRisksModelUpdateRulesSuccess(response.data));
    yield put(amlRiskModelInfoSuccess(response.data));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(amlRisksModelUpdateRulesFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(amlSingleStateRequest.type, getAmlSingleState),
    takeLatest(amlSingleCheckRequest.type, amlSingleCheck),
    takeLatest(amlSingleChecksRequest.type, amlSingleChecks),
    takeLatest(amlStateRequest.type, getAmlState),
    takeLatest(amlCheckManuallyRequest.type, amlCheckManually),
    takeLatest(amlRiskSummaryRequest.type, getAmlRiskSummary),
    takeLatest(amlPublicSingleRequest.type, getAmlPublicSingle),
    takeLatest(amlRisksModelsRequest.type, amlRisksModels),
    takeLatest(amlRiskModelInfoRequest.type, amlRiskModelInfo),
    takeLatest(amlRisksModelCreateRequest.type, amlRisksModelCreate),
    takeLatest(amlRisksModelActivateRequest.type, amlRisksModelActivate),
    takeLatest(amlRisksModelDeleteRequest.type, amlRisksModelDelete),
    takeLatest(amlRisksEntityCategoriesRequest.type, amlRisksEntityCategories),
    takeLatest(amlRisksModelEditRequest.type, amlRisksModelEdit),
    takeLatest(amlRisksModelRulesRequest.type, amlRisksModelRules),
    takeLatest(amlRisksModelUpdateRulesRequest.type, amlRisksModelUpdateRules),
  ]);
}

export default Saga;

