import { FC } from "react";
import round from "lodash/round";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ECurrency } from "config/types";

import { servicesAllSelectByCodeSelector } from "store/services/selectors";

import { currencySymbol } from "utils/currency";

import { TAMLActivatePackProps } from "../../index";

const AMLActivatePackContent: FC<TAMLActivatePackProps> = ({
  code, count, title, toFixed=1
}) => {
  const { t } = useTranslation();
  const service = useSelector(servicesAllSelectByCodeSelector(code));
  const price = service?.price ? round(service?.price / count, 2) : 0;
  return (<>
    <div className="aml-activate-pack__left">
      <div className="aml-activate-pack__check" dangerouslySetInnerHTML={{ __html: title }} />
      <div className="aml-activate-pack__per-check">
        {
          service?.currency === ECurrency.usd
            ? <>
              {currencySymbol(service?.currency)}{price} {t("amlPricing.perCheck")}
            </>
            : <>
              {price.toFixed(0)} {currencySymbol(service?.currency)} {t("amlPricing.perCheck")}
            </>
        }
      </div>
    </div>
    <div className="aml-activate-pack__right">
      <div className="aml-activate-pack__price">
        {
          service?.currency === ECurrency.usd
            ? <>
              <span className="aml-activate-pack__price__currency">{currencySymbol(service?.currency)}</span>
              <span>{service?.price ? round(service?.price, 1).toFixed(toFixed) : 0}</span>
            </>
            : <>
              <span>{service?.price ? round(service?.price).toFixed(0) : 0}</span>
              <span className="aml-activate-pack__price__currency">{currencySymbol(service?.currency)}</span>
            </>
        }
      </div>
    </div>

  </>);
};

export default AMLActivatePackContent;
