import { OutputSelector } from "reselect";

import { TNullable } from "../../config/types";

import { IApplicationState, IPaginationResponse, TRequestHandler } from "../rootInterface";

export enum EAmlSingleStateCheckStatus {
  not_checked = "not_checked",
  pending = "pending",
  checked = "checked"
}

export type TAmlSingleLowRiskDetails = {
  miner: number;
  wallet: number;
  payment: number;
  marketplace: number;
  exchange_mlrisk_low: number;
  p2p_exchange_mlrisk_low: number;
  exchange_mlrisk_moderate: number;
}
export type TAmlSingleMediumRiskDetails = {
  atm: number;
  exchange_mlrisk_high: number;
  exchange_mlrisk_veryhigh: number;
  p2p_exchange_mlrisk_high: number;
}
export type TAmlSingleRiskDetails = {
  scam: number;
  mixer: number;
  ransom: number;
  gambling: number;
  dark_market: number;
  dark_service: number;
  stolen_coins: number;
  illegal_service: number;
  exchange_fraudulent: number;
}
export type TAmlSingleStateData = {
  check_status: EAmlSingleStateCheckStatus;
  last_checked: TNullable<string>;
  last_check_id: TNullable<string>;
  risk_score: number; // 0 - 1
  new_transactions: TNullable<number>;
  low_risk_value: number;
  medium_risk_value: number;
  high_risk_value: number;
  unknown_value: number;
  low_risk_details: TAmlSingleLowRiskDetails,
  medium_risk_details: TAmlSingleMediumRiskDetails,
  high_risk_details: TAmlSingleRiskDetails
}
export type TAmlSingleStateState = TRequestHandler<TAmlSingleStateData>

export type TAmlSingleCheckState = TRequestHandler<unknown>


export type TAmlSingleChecksOptions = {
  account?: string,
  manual?: boolean,
  archived?: boolean,
  offset?: TNullable<number>,
  limit?: number,
  loadMore?: boolean
}
export enum TAmlSingleChecksSubject {
  addr = "addr",
  tx = "tx",
}
export type TAmlSingleChecksData = {
  id: string;
  network: string;
  subject: TAmlSingleChecksSubject;
  value: string;
  status: EAmlSingleStateCheckStatus;
  checked_at: string;
  risk_score: number;
  low_risk_value: number;
  medium_risk_value: number;
  high_risk_value: number;
  unknown_value: number;
  low_risk_details: TAmlSingleLowRiskDetails;
  medium_risk_details: TAmlSingleMediumRiskDetails;
  high_risk_details: TAmlSingleRiskDetails;
}
export type TAmlSingleChecksState = TRequestHandler<IPaginationResponse<TAmlSingleChecksData>>
  & { prevData: TNullable<IPaginationResponse<TAmlSingleChecksData>> }

export enum EAmlSingleIds {
  archived= "archived",
  manual= "manual",
}

export enum TAmlStateManualCheckStatuses {
  checking = "checking",
  ready = "ready",
}
export type TAmlStateData = {
  available_checks: number;
  manual_checks: number;
  archived_checks: number;
  manual_check_status: TAmlStateManualCheckStatuses;
}
export type TAmlStateState = TRequestHandler<TAmlStateData>

export type TAmlCheckManuallyData = TAmlSingleChecksData
export type TAmlCheckManuallyState = TRequestHandler<TAmlCheckManuallyData>

export type TAmlRiskSummaData = {
  accounts: number // число привязанных кошельков (для проверок)
  risk_score: TNullable<number> // общий риск (от 0 до 1) по привязанным кошелькам.
  alarm_message: TNullable<string> // строка, сообщение-предупреждение о рисках.
  account_to_check: TNullable<string> // id аккаунта, который должен быть проверен прежде всего.
}
export type TAmlRiskSummaryState = TRequestHandler<TAmlRiskSummaData>

export type AmlPublicSingleData = {
  id: string;
  network: string;
  network_name: string
  address: TNullable<string>
  tx_hash: TNullable<string>
  checked_at: string;
  risk_score: number;
  low_risk_value: number;
  medium_risk_value: number;
  high_risk_value: number;
  unknown_value: number;
  low_risk_details: TAmlSingleLowRiskDetails;
  medium_risk_details: TAmlSingleMediumRiskDetails;
  high_risk_details: TAmlSingleRiskDetails;
}
export type TAmlPublicSingleState = TRequestHandler<AmlPublicSingleData>

export type AmlRiskModel = {
  id: string
  name: string
  created_at: string
  updated_at: string
  is_built_in: boolean
  is_active: boolean
  rule_count: number
}
export type AmlRisksModelsState = TRequestHandler<AmlRiskModel[]>

export type AmlRiskModelInfoOptions = {
  id: string
}
export type AmlRiskModelInfoState = TRequestHandler<AmlRiskModel>

export type AmlRisksModelCreateData = {
  name: string
  base_model: TNullable<string>
}
export type AmlRisksModelCreateOptions = {
  data: AmlRisksModelCreateData
  callOnSuccess?: (val: AmlRiskModel) => void
}
export type AmlRisksModelCreateState = TRequestHandler<AmlRiskModel>

export type AmlRisksModelActivateOptions = {
  id: string
  callOnSuccess?: () => void
}
export type AmlRisksModelActivateState = TRequestHandler<unknown>

export type AmlRisksModelDeleteOptions = {
  id: string
  callOnSuccess?: () => void
}
export type AmlRisksModelDeleteState = TRequestHandler<unknown>

export type EntityCategory = {
  id: string
  name: string
}
export type AmlRisksEntityCategoriesOptions = {
  callOnSuccess?: (value: EntityCategory[]) => void
}
export type AmlRisksEntityCategoriesState = TRequestHandler<EntityCategory[]>


export type AmlRisksModelEditData = {
  name: string
}
export type AmlRisksModelEditOptions = {
  id: string
  data: AmlRisksModelEditData
  callOnSuccess?: () => void
}
export type AmlRisksModelEditState = TRequestHandler<AmlRiskModel>
export enum RuleLevel {
  low = "low",
  medium = "medium",
  high = "high",
  severe = "severe",
}
export enum RuleDirection {
  receiving = "receiving",
  sending = "sending",
  both = "both",
}
export enum RuleExposure {
  direct = "direct",
  indirect = "indirect",
  both = "both",
}
export type Rule = {
  id: string
  created_at: string
  updated_at: TNullable<string>
  model: string
  entity_category: string
  level: RuleLevel
  min_usd: number
  min_percent: number
  direction: RuleDirection
  exposure: RuleExposure
}
export type AmlRisksModelRulesOptions = {
  id: string
}
export type AmlRisksModelRulesState = TRequestHandler<Rule[]>

export type AmlRisksModelUpdateRulesData = {
  rules_to_create: {
    entity_category: string
    level: string
    min_usd: number
    min_percent: number
    direction: string
    exposure: string
  }[]
  rules_to_update: {
    id: string
    entity_category: string
    level: string
    min_usd: number
    min_percent: number
    direction: string
    exposure: string
  }[]
  rules_to_remove: string[]
}
export type AmlRisksModelUpdateRulesOptions = {
  id: string
  data: AmlRisksModelUpdateRulesData
  callOnSuccess?: () => void
}
export type AmlRisksModelUpdateRulesState = TRequestHandler<AmlRiskModel>

export type TAmlStoreState = {
  amlSingleStateState: Record<string, TAmlSingleStateState>;
  amlSingleCheckState: TAmlSingleCheckState;
  amlSingleChecksState: Record<string, TAmlSingleChecksState>;
  amlStateState: TAmlStateState;
  amlCheckManuallyState: TAmlCheckManuallyState
  amlRiskSummaryState: TAmlRiskSummaryState
  amlPublicSingleState: TAmlPublicSingleState
  amlRisksModelsState: AmlRisksModelsState
  amlRiskModelInfoState: AmlRiskModelInfoState
  amlRisksModelCreateState: AmlRisksModelCreateState
  amlRisksModelActivateState: AmlRisksModelActivateState
  amlRisksModelDeleteState: AmlRisksModelDeleteState
  amlRisksEntityCategoriesState: AmlRisksEntityCategoriesState
  amlRisksModelEditState: AmlRisksModelEditState
  amlRisksModelRulesState: AmlRisksModelRulesState
  amlRisksModelUpdateRulesState: AmlRisksModelUpdateRulesState
  amlActivateDropdown: boolean
}

export type TAmlOutputSelector<T> = OutputSelector<IApplicationState, T, (s: TAmlStoreState) => T>;
