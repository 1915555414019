import { FC } from "react";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
import { Radio } from "antd";
import isFunction from "lodash/isFunction";

import { maxMobileMediaWidth } from "config/constants";
import { EQueries } from "config/types";

import { useBNDropdownContext } from "components/BNDropdown/hooks";

import useQuery from "hooks/useQuery";

import { EServiceCode, ETariffPlan } from "store/services/types";
import { EModals } from "store/modals/types";

import { useAMLActivateModalContext } from "../AMLActivateModal";

import AMLActivatePackContent from "./components/AMLActivatePackContent";

export type TAMLActivatePackProps = {
  code: EServiceCode
  count: number
  title: string
  toFixed?: number
  plan?: ETariffPlan
}

const AMLActivatePack: FC<TAMLActivatePackProps> = props => {
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const history = useHistory();
  const query = useQuery();
  const { formValue } = useAMLActivateModalContext();
  const { setActive } = useBNDropdownContext();

  const openModal = () => {
    if (!isMaxMobile) {
      query.set(EQueries.modal, EModals.activatePricing);
      query.set(EQueries.code, props.code);
      if (props.plan) query.set(EQueries.tariff, props.plan);
      history.replace({
        search: query.toString()
      });
      if (isFunction(setActive)) setActive(false);
    }
  };

  const handleValue = () => {
    if (!formValue) {
      return props.code;
    }
    return formValue({ code: props.code , plan: props.plan });
  };

  return (
    <div className="aml-activate-pack" onClick={openModal}>
      {isMaxMobile ? (<Radio
        key={props.code}
        value={handleValue()}
        className="aml-activate-modal__radio"
      >
        <AMLActivatePackContent {...props} />
      </Radio> )
        : <AMLActivatePackContent {...props} />}
    </div>
  );
};

export default AMLActivatePack;
