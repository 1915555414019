import { AxiosError } from "axios";
import { OutputSelector } from "reselect";
import { Dayjs } from "dayjs";

import { AxiosDataError } from "API/types";

import { TNullable, EStatus, ECurrency, ETransactionsOperationsTypes } from "config/types";

import { TMyAssetsData } from "store/assets/types";
import { TTransactionsFilterState } from "store/filter/types";
import { TTransactionResult } from "store/transactions/types";

import { ELanguages } from "../../i18n/constants";

import { IApplicationState, IPaginationResponse, TRequestHandler } from "../rootInterface";

export enum ESingleTaxReportTransactionStatus {
  not_formed = "not_formed",
  forming = "forming",
  formed = "formed",
  confirmed = "confirmed",
  error = "error"
}

export enum EReportTransactionType {
  all = "all",
  income = "income",
  outcome = "expense",
  manual = "manual",
  detailsRequired = "details_required",
  filter = "filter",
}

export enum ETaxTxTypeTransactionType {
  all = "all",
  income = "income",
  disposal = "disposal",
  acquisition = "acquisition",
}

export enum EReportTransactionTypeRu {
  all = "transactionTypes.allOperations",
  income = "operations.profitable",
  outcome = "operations.consumables",
  manual = "operations.addedManually",
  detailsRequired = "operations.unconfirmed",
  disposal = "operations.disposal",
  acquisition = "operations.acquisition",
  filter = "naming.filter"
}

export enum EReportMode {
  // https://bitnalog.myjetbrains.com/youtrack/issue/DEV-601/F-Tax-Report-Obrabotka-parametra-mode
  full = "full", // декларация формируется без ограничений.
  draft = "draft", // возможность дойти только до Шага 2 Конструктора декларации
  // https://bitnalog.myjetbrains.com/youtrack/issue/DEV-603/F-Transactions-export-Obrabotka-parametra-mode
  limit_35_days = "limit_35_days", //  позволяет сформировать выгрузку только за последние 35 дней.
  limit_1000_txs = "limit_1000_txs"
}

export enum ECostBasis {
  FIFO = "FIFO",
  LIFO = "LIFO",
  HIFO = "HIFO",
}

export type TReportParams = {
  mode: EReportMode
}

export type TReportData = {
  next?: TNullable<number>;
  previous?: TNullable<number>;
  count?: number;
  results: TReport[];
};

export type TSingleReportPayload = {
  id: string,
  callOnError?: (val: string) => void
}

export type TReport = {
  id: string;
  type: string;
  name: string;
  status: EStatus;
  created_at: string;
  updated_at?: string;
  user: string;
  documents: TDocument[];
  params?: TReportParams
};

export type TDocument = {
  id: TNullable<string>;
  report: string;
  name: string;
  doc_type: string;
  tag?: string;
  file: TNullable<string>;
  status: EStatus;
};

export type TReportsState = TRequestHandler<TReportData>

export type TNewReportOptionData = {
  ifns: string;
  oktmo: string;
  inn: string;
  first_name: string;
  last_name: string;
  middle_name: TNullable<string>;
  birthdate: TNullable<string>;
  phone: string;
  passport_series?: string;
  passport_number?: string;
};

export type TOldReportOptionData = {
  year: number;
  tax_authority: string;
  oktmo_code?: string;
  inn: string;
  first_name: string;
  last_name: string;
  patronymic_name: TNullable<string>;
  birth_date: string;
  birth_place: string;
  phone: string;
  passport_series?: string;
  passport_number?: string;
  passport_issued_by: string;
  passport_date_issued: string;
};

export type TOldReportOptions = {
  data: TOldReportOptionData;
  callOnSuccess: () => void;
};

export type TNewReportData = {
  detail: string;
};

export type TNewReportState = TRequestHandler<TNewReportData>

export type TReportDeleteState = TRequestHandler<TNewReportData>

export type TReportTaxAmountData = {
  year: number;
  amount: string;
  income: string;
  currency: ECurrency;
}

export type TReportTaxAmount = TRequestHandler<TReportTaxAmountData[]>

export type TCreateTaxReportStepOne = {
  assetsCheckList: TNullable<string[]>,
  synchronizedList: string[],
  assetsList: {
    fetching: boolean;
    data: TMyAssetsData[];
    failure: TNullable<AxiosDataError>;
  },
  includeManuals: boolean
}

export enum ECreateTaxReportSteps {
  one=0,
  two=1,
  three=2
}

export type TCreateTaxReportStepsState = {
  currentStep: ECreateTaxReportSteps;
  stepOne: TCreateTaxReportStepOne;
  isAnonymous: boolean;
}

export type TCreateTaxReportingProjectPayload = {
  data: {
    accounts: string[],
    year: string,
    include_manuals: boolean
  }
  callOnSuccess: (val: string) => void
}

export type TCreateTaxReportingProjectData = {
  report_id: string
}

export type TCreateTaxReportingProject = TRequestHandler<TCreateTaxReportingProjectData>

export type TSingleTaxReportData = {
  include_manuals: boolean;
  transactions_status: ESingleTaxReportTransactionStatus;
  personal_data_status: string;
  accounts: string[];
  status: string;
  id: string;
  created_at: string;
  updated_at: string;
  report_params: TReportParams
  name: string
};

export type TSingleTaxReportsState = TRequestHandler<TSingleTaxReportData>

export type TSinglePersonalData = TRequestHandler<TNewReportOptionData>

export type TReportTransactionResult = {
  checked: boolean,
  tax_report_transaction_id: string
} & TTransactionResult;

export type TReportTransactionsData = IPaginationResponse<TReportTransactionResult>;

export interface IReportTransactionsSuccess {
  page: number;
  data: TReportTransactionResult[];
  next: number | null;
  count: TNullable<number>;
}

export type TGetReportTransactionsPayload = {
  id: string,
  page?: number;
  type?: string;
  size?: number;
  isResult?: boolean;
  taxTxType?: boolean;
} & TTransactionsFilterState

export type TReportTransaction = {
  data: TReportTransactionResult[];
  fetching: boolean;
  error?: AxiosError;
  finish: boolean;
  next: number | null;
  allCheck: boolean;
  count: TNullable<number>;
  report_type?: EReportTransactionType;
  tax_tx_type?: ETaxTxTypeTransactionType;
  showMobileFilter: boolean
}

export type TReportTransactionType = {
  type: string,
  count: number,
}

export type ReportTaxTransactionTypes = {
  checked: number
} & TReportTransactionType

export type TReportTaxTransactionTypesStateData = {
  types: ReportTaxTransactionTypes[]
}

export type TResultReportTaxTransactionStateData = {
  types: TReportTransactionType[]
}

export type TReportTaxTransactionTypesState = TRequestHandler<TReportTaxTransactionTypesStateData>

export type TResultReportTaxTransactionState = TRequestHandler<TResultReportTaxTransactionStateData>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TReportAgreement = TRequestHandler<any> // TODO: need up

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TChangeReportCheckStatusData = any; // TODO: need up

export type TChangeReportCheckStatusPayload = {
  id: string,
  reportId: string,
  checked: boolean
}

export type TChangeReportCheckStatus = TRequestHandler<TChangeReportCheckStatusData>

export type TAllChangeReportCheckStatusData = {
  checked: boolean;
  type: string
}

export type TAllChangeReportCheckStatusPayload = {
  id: string
} & TAllChangeReportCheckStatusData;

export type TAllChangeReportCheckStatus = TRequestHandler<TAllChangeReportCheckStatusData>

export type TCreatePersonalDataPayload = {
  id: string;
  isAnonymous: boolean;
} & TNewReportOptionData;

export type TCreatePersonalData = TRequestHandler<TNewReportOptionData>;

export type TCreatePersonalDataComplete = TRequestHandler<TNewReportOptionData>;

export type TTaxReportResultData = {
  files: TDocument[];
  tax_amount: number;
  tax_base: number;
  total_expenses: number;
  income: number;
  report_params: TReportParams
}

export type TTaxReportsResultState = TRequestHandler<TTaxReportResultData>;

export type TUpdateTaxReportAccountsPayload = {
  id: string;
  changeStepCallback: () => void;
  accounts: string[];
  include_manuals: boolean;
}

export type TUpdateTaxReportAccounts = TRequestHandler<TCreateTaxReportingProjectData>;

export type TSingleTaxReportState = Record<string, TSingleTaxReportsState>

export type TAddTransactionToTaxReportOptions = {
  report_id: string;
  data: {transaction_id: string}
}

export type TAddTransactionToTaxReportData = {
  detail: string
}

export type TAddTransactionToTaxReportState = TRequestHandler<TAddTransactionToTaxReportData>


export type TCreateUnifiedTaxReportOptions = {
  data: {
    accounts: string[],
    include_manuals: boolean
    year: string,
    currency: ECurrency
    cost_basis: ECostBasis
    time_zone: string
  }
  callOnSuccess: () => void,
}

export type TCreateUnifiedTaxReportData = {
  report_id: string
}

export type TCreateUnifiedTaxReportState = TRequestHandler<TCreateUnifiedTaxReportData>

export type TUpdateUnifiedTaxReportOptions = {
  id: string
} & TCreateUnifiedTaxReportOptions

export type TUpdateUnifiedTaxReportState = TRequestHandler<TCreateUnifiedTaxReportData>

export type TSingleUnifiedTaxReportData = {
  id: string
  status: string
  created_at: string
  updated_at: string
  base_currency: string
  accounts: string[],
  include_manuals: boolean
  transactions_status: ESingleTaxReportTransactionStatus
  report_params: TReportParams
  year: number
  cost_basis: ECostBasis
  time_zone: string
  name: string
}

export type TSingleUnifiedTaxReportState = TRequestHandler<TSingleUnifiedTaxReportData>

export type TUnifiedReportTaxTransactionTypesTransactions = {
  types: ReportTaxTransactionTypes[]
}

export type TUnifiedTaxReportTransactionTypesData = {
  types: TReportTransactionType[]
}

export type TUnifiedTaxReportFormOptions = { id: string, callOnSuccess: () => void }
export type TUnifiedTaxReportFormState = TRequestHandler<unknown>;

export type TUnifiedTaxReportTransactionTypesState = TRequestHandler<TUnifiedTaxReportTransactionTypesData>

export enum TCreateTransitionExportSteps {
  one = 0,
  two = 1,
  three = 2
}

export type TCreateTransitionExportStepOne = {
  assetsCheckList: TNullable<string[]>,
  synchronizedList: string[],
  assetsList: {
    fetching: boolean;
    data: TMyAssetsData[];
    failure: TNullable<AxiosDataError>;
  },
  includeManuals: boolean
}

export type TCreateTransitionExportStepTwo = {
  language?: TNullable<ELanguages>,
  date_from?: TNullable<Dayjs>,
  date_to?: TNullable<Dayjs>,
  types?: ETransactionsOperationsTypes[]
}

export type TCreateTransitionExport = {
  currentStep: TCreateTransitionExportSteps;
  stepOne: TCreateTransitionExportStepOne;
  stepTwo: TCreateTransitionExportStepTwo;
}

export type TCreateTransactionExportOptions = {
  report_id?: string;
  accounts: string[];
  date_from: string;
  date_to: string;
  types: string[];
  language: ELanguages;
}

export type TCreateTransactionExportData = {
  id: string;
}

export type TCreateTransactionExportState = TRequestHandler<TCreateTransactionExportData>

export enum EReportType {
  tax_report = "tax_report",
  unified_tax_report = "unified_tax_report",
  transaction_export = "transaction_export",
  sources_export = "sources_export",
}
export enum EReportStatus {
  formed = "formed",
  forming = "forming",
  error = "error",
  draft = "draft"
}
export type TReportSingleData = {
  name: string;
  type: EReportType;
  status: EReportStatus;
  files: TDocument[];
  formed_at: string;
  params: TReportParams
}
export type TReportSingleState = TRequestHandler<TReportSingleData> & { initialLoading: boolean }

export type TCreateSourcesExportOptions = {
  data: TCreateTransactionExportOptions
  callback: (v?: string) => void
};
export type TCreateSourcesExportData = {
  id: string;
}
export type TCreateSourcesExportState = TRequestHandler<TCreateSourcesExportData>

export type TReportsTypesData = {
  code: EReportType
  soon: boolean
  name: string
  params: TReportParams
}
export type TReportsTypesState = TRequestHandler<TReportsTypesData[]>

export type TReportsStoreState = {
  reportsState: TReportsState;
  singleTaxReport: TSingleTaxReportState;
  singlePersonalData: Record<string, TSinglePersonalData>;
  createPersonalData: TCreatePersonalData;
  createPersonalDataComplete: TCreatePersonalDataComplete;
  newReportState: TNewReportState;
  deleteReportState: TReportDeleteState;
  taxAmountReport: TReportTaxAmount;
  createTaxReportSteps: Record<string, TCreateTaxReportStepsState>,
  createTransitionExport: Record<string, TCreateTransitionExport>,
  createTaxReportingProject: TCreateTaxReportingProject,
  createUnifiedTaxReportState: TCreateUnifiedTaxReportState,
  updateUnifiedTaxReportState: TUpdateUnifiedTaxReportState,
  singleUnifiedTaxReport: Record<string, TSingleUnifiedTaxReportState>,
  unifiedTaxReportTransactionTypesState: Record<string, TUnifiedTaxReportTransactionTypesState>
  unifiedTaxReportFormState: Record<string, TUnifiedTaxReportFormState>
  updateTaxReportAccounts: TUpdateTaxReportAccounts,
  reportTransactions: Record<string, TReportTransaction>;
  reportTaxTransactionTypesState: Record<string, TReportTaxTransactionTypesState>;
  reportTaxResultTransactionTypesState: Record<string, TResultReportTaxTransactionState>;
  reportAgreement: TReportAgreement;
  changeReportCheckStatus: Record<string, TChangeReportCheckStatus>;
  changeAllReportCheckStatus: TAllChangeReportCheckStatus;
  taxReportResultState: TTaxReportsResultState;
  addTransactionToTaxReportState: TAddTransactionToTaxReportState;
  createTransactionExportState: TCreateTransactionExportState;
  createSourcesExportState: TCreateSourcesExportState;
  reportSingleState: TReportSingleState;
  reportsTypesState: TReportsTypesState;
};


export type TReportsOutputSelector<T> = OutputSelector<IApplicationState, T, (s: TReportsStoreState) => T>;
