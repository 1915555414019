import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { servicesAllRequest } from "store/services/reducers";
import { AmlPacksCount, EServiceCode, EServiceSet, ETariffPlan } from "store/services/types";
import { userPlanInfoSelector } from "store/user/selectors";
import { servicesExistsSelector } from "store/services/selectors";

import AMLActivatePack from "../AMLActivatePack";

const AMLActivatePacks: FC = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: userPlanInfo } = useSelector(userPlanInfoSelector);
  const servicesExists = useSelector(servicesExistsSelector);

  const showSmart = userPlanInfo?.current_plan.tariff_plan &&
    ![ETariffPlan.pro, ETariffPlan.smart].includes(userPlanInfo?.current_plan.tariff_plan);
  const showPro = userPlanInfo?.current_plan.tariff_plan !== ETariffPlan.pro;

  useEffect(() => {
    dispatch(servicesAllRequest({ params: { set: EServiceSet.amlCheck } }));
  }, [dispatch]);

  return (
    <div className="aml-activate-packs">
      {servicesExists[EServiceCode.amlPacks1] && <AMLActivatePack
        code={EServiceCode.amlPacks1}
        count={AmlPacksCount[EServiceCode.amlPacks1]}
        title={t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks1] })} />}
      {servicesExists[EServiceCode.amlPacks5] && <AMLActivatePack
        code={EServiceCode.amlPacks5}
        count={AmlPacksCount[EServiceCode.amlPacks5]}
        title={t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks5] })}/>}
      {servicesExists[EServiceCode.amlPacks10] && <AMLActivatePack
        code={EServiceCode.amlPacks10}
        count={AmlPacksCount[EServiceCode.amlPacks10]}
        title={t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks10] })}/>}
      {(showSmart || showPro) && <div className="aml-activate-packs__subscriptions">
        {t("amlPricing.subscriptionsChecks")}:
      </div>}
      {servicesExists[EServiceCode.plansSmartMonth] && showSmart && <AMLActivatePack
        code={EServiceCode.plansSmartMonth} count={30} title="Smart" toFixed={0} plan={ETariffPlan.smart}/>}
      {servicesExists[EServiceCode.plansProMonth] && showPro && <AMLActivatePack
        code={EServiceCode.plansProMonth} count={60} title="Pro" toFixed={0} plan={ETariffPlan.pro}/>}
    </div>
  );
};

export default AMLActivatePacks;
