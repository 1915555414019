import { FC } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import round from "lodash/round";

import { ECurrency, TNullable } from "config/types";

import { AmlPacksCount, EServiceCode, ETariffPlan } from "store/services/types";

import { currencySymbol } from "utils/currency";

export type TPriceActivateMainInfoBodyProps = {
  tariff: ETariffPlan
  code: EServiceCode
  currency?: ECurrency
  price?: number
  menuElement?: boolean
  onClick?: () => void
  monthlyPayment?: TNullable<number>
}

const PriceActivateMainInfoBody: FC<TPriceActivateMainInfoBodyProps> = ({
  tariff,
  code,
  price=0,
  currency= ECurrency.rub,
  monthlyPayment,
  menuElement,
  onClick
}) => {
  const { t } = useTranslation();
  const isMonthly = code.includes("month");

  const formPricing = () => {
    if (code === EServiceCode.amlPacks1) {
      return t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks1] });
    } else if (code === EServiceCode.amlPacks5) {
      return t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks5] });
    } else if (code === EServiceCode.amlPacks10) {
      return t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks10] });
    }

    switch (tariff) {
    case ETariffPlan.smart:
      return t("pricing.smart");
    case ETariffPlan.pro:
      return t("pricing.pro");
    default:
      return "";
    }
  };

  const perCheck = (count: number) => {
    const $price = price ? round(price / count, 1) : 0;
    if (currency === ECurrency.usd) {
      return <>
        {currencySymbol(currency)}
        {$price.toFixed(1)}
        {" "}
        {t("amlPricing.perCheck")}
      </>;
    }
    return <>{$price.toFixed(0)} {currencySymbol(currency)} {t("amlPricing.perCheck")}</>;
  };

  const formPlan = () => {
    if (code === EServiceCode.amlPacks1) {
      return perCheck(AmlPacksCount[EServiceCode.amlPacks1]);
    } else if (code === EServiceCode.amlPacks5) {
      return perCheck(AmlPacksCount[EServiceCode.amlPacks5]);
    } else if (code === EServiceCode.amlPacks10) {
      return perCheck(AmlPacksCount[EServiceCode.amlPacks10]);
    }

    if (isMonthly) {
      return t("pricing.monthlySubsctiption");
    }
    return t("pricing.annuallySubsctiption");
  };

  const formPrice = (): number|string => {
    let $price: number|string = price;

    if (currency === ECurrency.rub) {
      $price = round(price).toFixed(0);
    }

    if (monthlyPayment) {
      $price = monthlyPayment;
    }
    return $price;
  };

  return (
    <div className={cn("pricing__activate__main-info__body", { menuElement })} onClick={onClick}>
      <div className="pricing__activate__main-info__content">
        <div className="pricing__activate__main-info__prising" dangerouslySetInnerHTML={{ __html: formPricing() }}/>
        <div className="pricing__activate__main-info__plan">
          { formPlan() }
        </div>
      </div>
      <div className="pricing__activate__main-info__price">
        {currency === ECurrency.usd && currencySymbol(ECurrency.usd)}
        <span>{formPrice()}</span>
        {currency === ECurrency.rub && currencySymbol(ECurrency.rub)}
        {monthlyPayment && ` / ${t("pricing.month")}`}
      </div>
    </div>
  );
};

export default PriceActivateMainInfoBody;
