import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { maxMobileMediaWidth } from "config/constants";
import { getAssetSingleId, getAssetsSinglePath, paths } from "config/paths";

import ConfirmModal from "components/ConfirmModal";
import Button from "components/Buttons/Button";
import DangerIcon from "components/Icons/DangerIcon";

import { getMyAssetsRequest, getSingleAssetDeleteRequest } from "store/assets/reducers";
import {
  getMyAssetsDataSelector,
  getSingleAssetDataSelector,
} from "store/assets/selectors";

import AssetSingleRenameModal from "../AssetSingleRenameModal";
import AssetSingleEditConnect from "../AssetSingleEditConnect";

import { EAssetsSingleMenuStatesKeys } from "./types";
import { useAssetsSingleMenuState } from "./hooks";
import AssetsSingleMenuDropdown from "./components/AssetsSingleMenuDropdown";

const AssetsSingleMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const id = getAssetSingleId();
  const { visible, setVisibleFalse, setVisibleTrue } = useAssetsSingleMenuState();
  const data = useSelector(getSingleAssetDataSelector(id));
  const myAssets = useSelector(getMyAssetsDataSelector);
  const isMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });

  const onDeleteAsset = () => {
    dispatch(getSingleAssetDeleteRequest({ id, callOnSuccess: redirectHandler }));
    setVisibleFalse(EAssetsSingleMenuStatesKeys.delete);
  };

  const redirectHandler = () => {
    dispatch(getMyAssetsRequest({}));

    if (!myAssets || myAssets?.length === 1 || isMobile) {
      history.push(paths.HOME);
      return;
    }
    const arr = [...myAssets];
    const index = arr.findIndex(item => item.id === id);
    arr.splice(index, 1);

    const first = arr[0];
    history.push(getAssetsSinglePath(first.id));
  };

  return (
    <>
      <AssetsSingleMenuDropdown
        setVisibleTrue={setVisibleTrue}
      />
      <ConfirmModal // ---
        icon={<DangerIcon />}
        title={t('accountSync.deleteAccountWithName', { name: data?.name })}
        text={t('accountSync.deleteAccountText')}
        visible={visible.delete}
        onCancel={() => setVisibleFalse(EAssetsSingleMenuStatesKeys.delete)}
        onOk={onDeleteAsset}
        btns={
          <>
            <Button
              title={t("action.cancel")}
              transparent={true}
              onClick={() => setVisibleFalse(EAssetsSingleMenuStatesKeys.delete)}
            />
            <Button title={t('action.delete')} danger={true} onClick={onDeleteAsset} />
          </>
        }
      />
      {data && (
        <AssetSingleRenameModal // ---
          visible={visible.rename}
          onCancel={() => setVisibleFalse(EAssetsSingleMenuStatesKeys.rename)}
          onOk={() => setVisibleFalse(EAssetsSingleMenuStatesKeys.rename)}
        />
      )}
      {data && (
        <AssetSingleEditConnect // ---
          visible={visible.connect}
          onCancel={() => setVisibleFalse(EAssetsSingleMenuStatesKeys.connect)}
          onOk={() => setVisibleFalse(EAssetsSingleMenuStatesKeys.connect)}
        />
      )}
    </>
  );
};

export default AssetsSingleMenu;
